<!--oa表单详情-->
<template>
    <div>
        <global-page-back
            :detailPageTitle="pageTitle"
            @handlerGlobalType="handleBack"
        >
            <template #globalPageBackTabs>
                <div class="tabs-list">
                    <div :class="['tabs-item',{ current: activeName === '1' }]" @click="changeTabs('1')">提交申请</div>
                    <div :class="['tabs-item',{ current: activeName === '2' }]" @click="changeTabs('2')">历史记录</div>
                </div>
            </template>
        </global-page-back>
        <div class="main-container">
            <coworking-campus-o-a-o-a-approval-submit
                v-if="activeName === '1'"
                @handleBack="handleBack"
                @handleSubmit="handleSubmit"
                @setTitle="setTitle"
            />
            <coworking-campus-o-a-o-a-approval-record
                v-if="activeName === '2'"
                @setTitle="setTitle"
            />
        </div>
    </div>
</template>

<script>
import CoworkingCampusOAOAApprovalSubmit from "@/components/scrollWrapper/CoworkingCampusOAOAApprovalSubmit";
import CoworkingCampusOAOAApprovalRecord from "@/components/scrollWrapper/CoworkingCampusOAOAApprovalRecord";
import {mapMutations, mapState} from "vuex";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";

export default {
    name: "groupDetail",
    components: {
        GlobalPageBack,
        CoworkingCampusOAOAApprovalSubmit,
        CoworkingCampusOAOAApprovalRecord
    },
    computed: {
        ...mapState({
            OAQueryData: state => state.OAQueryData
        }),
    },
    data() {
        return {
            activeName: '1',
            pageTitle: ''
        }
    },
    created() {
        this.$eventDispatch('setGlobalPageType', 'detail')
        if (this.$route.query.activeName) {
            this.activeName = this.$route.query.activeName
        }
    },
    activated() {
        this.setGlobalPageType()
    },
    methods: {
        ...mapMutations(['setOAQueryData']),
        handleBack() {
            if (this.OAQueryData.backData) {
                this.$router.push({
                    name: this.OAQueryData.backData.name
                })
            } else {
                this.$router.go(-1)
            }
        },
        handleSubmit() {
            this.$router.push({
                name: this.OAQueryData.backData.name,
                query: {
                    activeName: '4'
                },
            })
        },
        changeTabs(name) {
            this.activeName = name
        },
        setTitle(title) {
            this.pageTitle = title
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .global-page-back {
    position: relative;
    z-index: 99;
    box-shadow: -2px 0px 8px 0px #E1E3E6;

    .back-wrapper {
        padding-right: 27px;
        border-right: 1px solid #D4D6D9;
    }

    .tab-list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.tabs-list {
    display: flex;
    height: 48px;
    line-height: 48px;

    .tabs-item {
        width: 96px;
        position: relative;
        text-align: center;
        font-size: 15px;
        color: #606C80;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #2B2E33;
        font-weight: bold;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            height: 3px;
            width: 30px;
            background: #3C7FFF;
            border-radius: 2px;
        }
    }
}

.main-container {
    height: calc(100vh - 123px);
}
</style>
