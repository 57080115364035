
<template>
    <!--首页-->
    <component :is="comp" :fromPath="fromPath"></component>
</template>

<script>
import FirstPage from "@/components/scrollWrapper/FirstPage";
import WorktopCustom from "@/components/scrollWrapper/WorktopCustom";

export default {
    components: {
        FirstPage,
        WorktopCustom
    },
    data() {
        return {
            comp: '',
            fromPath:'',
            // ab12433783574cc38ff27d11fa1d6aa8 开发环境四十三中
            // ade44974626fa517e34ae36c9cbc0e0f 测试环境智慧星2.3.3
            // de089fa35dafd39140567cbad5c0c8b9 生产环境滨江中学
            customSchoolId: ['ab12433783574cc38ff27d11fa1d6aa8', 'ade44974626fa517e34ae36c9cbc0e0f', 'de089fa35dafd39140567cbad5c0c8b9']
        }
    },
    beforeRouteEnter (to, from, next) {
		/*
		to:获取你要跳转的路由信息
		from: 获取你从哪个路由来的信息
		next: （放行函数）
		 // 第一种写法
		next(); // 直接放行
		// 第二种
		next('/url') //放行到指定的路由
		*/
		 next(vm => {
            vm.fromPath = from.path
        })
    },
    mounted() {
        if (this.customSchoolId.includes(this.$store.state.schoolId)) {
            this.comp = 'WorktopCustom'
        } else {
            this.comp = 'FirstPage'
        }
    }
}
</script>
