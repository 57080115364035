<template>
    <!-- 协同办公 > 校园OA > 审批管理 -->
    <div>
        <component
            v-bind:is="currentTabComponent"
            @changeComp="changeComp"
            @handlerGlobalType="handlerGlobalType"
            @setPageInitTabs="setPageInitTabs"
            ></component>
    </div>
</template>
<script>
// 引入审批管理组件
import equipmentManageScrollWrapper from '@/components/scrollWrapper/DeviceManagement/equipmentManagement/Index'
// 引入创建表单组件
import SchoolAddressScrollWrapper from '@/components/scrollWrapper/DeviceManagement/SchoolAddress/Index'
import commodityPurchaseScrollWrapper from '@/components/scrollWrapper/DeviceManagement/CommodityPurchase/index'
export default {
    name: "CoworkingApprovalManage",
    components: {
        equipmentManageScrollWrapper,
        SchoolAddressScrollWrapper,
        commodityPurchaseScrollWrapper
    },
    data() {
        return {
            // 切换组件
            comp: ['equipmentManageScrollWrapper', 'SchoolAddressScrollWrapper','commodityPurchaseScrollWrapper'],
            idx: 0,
            accessStatus: ''
        }
    },
    created () {
        console.log('0-0-0-0')
    },
    computed : {
        currentTabComponent () {
            return this.comp[this.idx];
        }
    },
    methods: {
        // 切换组件
        changeComp (status) {
           this.idx = status == 'back' ? 0 : status == 'schoolAddress' ? 1 : 2;
           if (status == 'back') {
            this.setGlobalPageType('list')
           } else {
            this.setGlobalPageType()
           }
        },
        handlerGlobalType () {
            this.changeComp('back')
        },
        setGlobalPageType (type = 'detail') {
            console.log(type,'type')
            this.$eventDispatch('setGlobalPageType', type)
        },
        setPageInitTabs(){
            this.setGlobalPageType('list')
            this.idx = 0;
        }
         
    }
}
</script>

