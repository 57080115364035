<template>
    <coworking-campus-oA-vacation-management-scroll-wrapper></coworking-campus-oA-vacation-management-scroll-wrapper>
</template>
<script>
import CoworkingCampusOAVacationManagementScrollWrapper from '@/components/scrollWrapper/CoworkingCampusOAVacationManagement.vue';
export default {
    name: 'CoworkingCampusOAVacationManagement',
    components: {
        CoworkingCampusOAVacationManagementScrollWrapper
    }

}
</script>
