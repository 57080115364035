<template>
  <!-- 协同办公 > 校园OA > 数据统计 -->
  <coworking-campus-o-a-data-statistics-scroll />
</template>

<script>
import CoworkingCampusOADataStatisticsScroll from '@/components/scrollWrapper/CoworkingCampusOADataStatistics'
export default {
  name: "CoworkingDataStatistics",
  components: {
    CoworkingCampusOADataStatisticsScroll
  }
}
</script>

<style scoped>

</style>
