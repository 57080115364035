<template>
    <!-- 协同办公 > 校园OA > 审批管理 -->
    <div style="height: calc(100vh - 75px)">
        <component
            v-bind:is="currentTabComponent"
            :showOverallDisplayOriData="showOverallDisplayOriData"
            :showOverallDisplayStatus="showOverallDisplayStatus"
            :accessStatus="accessStatus"
            @changeComp="changeComp"
            @showOverallDisplayData="showOverallDisplayData"
            @setPageInitTabs="setPageInitTabs"
        ></component>
    </div>
</template>
<script>
export default {
    name: "CoworkingApprovalManage",
    components: {
        CoworkingCampusOAApprovalManageScrollWrapper : () => import(/* webpackChunkName:'CoworkingCampusOAApprovalManage' */"@/components/scrollWrapper/CoworkingCampusOAApprovalManage.vue"), // 引入审批管理组件
        CoworkingCampusOAApprovalManageForm : () => import(/* webpackChunkName:'CoworkingCampusOAApprovalManageForm' */"@/components/scrollWrapper/CoworkingCampusOAApprovalManageForm.vue"),// 引入创建表单组件
    },
    data() {
        return {
            // 切换组件
            comp: ['CoworkingCampusOAApprovalManageScrollWrapper', 'CoworkingCampusOAApprovalManageForm'],
            idx: 0,
            showOverallDisplayOriData: {},
            showOverallDisplayStatus: false,
            accessStatus: 'inslider',
            type: 'list',
        }
    },
    created () {
        this.init();
    },
    activated() {
        this.setGlobalPageType(this.type)
    },
    computed : {
        currentTabComponent () {
            return this.comp[this.idx];
        }
    },
    methods: {
        init () {
            this.checkHasGoDetail()
            console.log(this.accessStatus, '12312312初始状态')
        },
        /**
         * @Description: 查看是否直接去创建表单页面
         * @Author: 武东超
         * @Date: 2024-09-05 09:15:54
         */        
        checkHasGoDetail() {
            const type = sessionStorage.getItem('GO_OA_MANAGE_DETAIL');
            if (type === 'go') {
                this.changeComp('add');
                sessionStorage.setItem('GO_OA_MANAGE_DETAIL', 'normal');
            }            
        },
        // 切换组件
        changeComp (status) {
            this.showOverallDisplayStatus = false;
            this.idx = status == 'back' ? 0 : 1;
            this.accessStatus = 'createdForm';
            this.showOverallDisplayOriData = {};
            this.setGlobalPageType(status == 'back' ? 'list' : 'detail')
            console.log(this.accessStatus, '1231231')
        },
        // 编辑获取详情数据
        showOverallDisplayData (data) {
            this.showOverallDisplayOriData = data;
            this.showOverallDisplayStatus = true;
            this.idx = 1;
            this.setGlobalPageType('detail')
            console.log('showOverallDisplayData')
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
        setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0;
        }
    }
}
</script>
