<template>
    <div class="empty">
        <!-- <transit-scroll-wrapper></transit-scroll-wrapper> -->
    </div>
</template>

<script>
import TransitScrollWrapper from '@/components/scrollWrapper/Transit.vue';

export default {
    name: 'Empty',
    components: {
        TransitScrollWrapper
    }
}
</script>

<style lang="scss" scoped>
.empty {
    height: 100vh;

    ::v-deep .content-wrapper{
        height: 100vh;
    }
}
</style>