<!-- 课程管理/跟课记录 -->
<template>
    <follow-class-record-scroll-wrapper></follow-class-record-scroll-wrapper>
</template>
<script>
import FollowClassRecordScrollWrapper from '@/components/scrollWrapper/FollowClassRecord.vue';
export default {
    name: "FollowClassRecord",
    components: {
        FollowClassRecordScrollWrapper
    }
}
</script>
