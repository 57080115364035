<template>
    <!-- 协同办公 > 校园OA > OA审批 -->
    <div>
        <tabbed-page
            :tabList="tabList"
            @changeTabs="handlerChangeTabs"
        >
        </tabbed-page>
        <div class="content-main" style="margin-top:0px;">
            <component
                :is="comps[idx]"
                :active-name="activeName"
                :upLoadAuth="upLoadAuth"
                :detailValue="detailValue"
                :key="activeName"
                @setPageInitTabs="setPageInitTabs"
                @handlerMore="handlerMore"
            >
            </component>
        </div>
    </div>   
</template>
<script>
// import CoworkingCampusOAOAApprovalAllScroll from "@/components/scrollWrapper/CoworkingCampusOAOAApprovalAll";
import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue'
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
import CoworkingOAApprovalScrollWrapper from '@/components/scrollWrapper/CoworkingOAApproval.vue';

export default {
    name: "CoworkingOAApproval",
    components: {
        // CoworkingCampusOAOAApprovalAllScroll,
        TabbedPage,
        CoworkingOAApprovalScrollWrapper,
        CoworkingCampusOAOAApprovalMyScroll : () => import(/* webpackChunkName:'CoworkingCampusOAOAApprovalMyScroll' */"@/components/scrollWrapper/CoworkingCampusOAOAApprovalMy")    
    },
    data() {
        return {
            activeName: '0',
            tabList: [
                {
                    id: '0',
                    title: '概览',
                    com: 'CoworkingOAApprovalScrollWrapper',
                    vHasPermi: ['from:all']
                },
                {
                    id: '1',
                    title: '待我审批',
                    com: 'CoworkingCampusOAOAApprovalMyScroll',
                    vHasPermi: ['waitMe:all']
                },
                {
                    id: '2',
                    title: '我已审批',
                    com: 'CoworkingCampusOAOAApprovalMyScroll',
                    vHasPermi: ['doneMe:all']
                },
                {
                    id: '3',
                    title: '抄送我的',
                    com: 'CoworkingCampusOAOAApprovalMyScroll',
                    vHasPermi: ['ccMe:all']
                },
                {
                    id: '4',
                    title: '我发起的',
                    com: 'CoworkingCampusOAOAApprovalMyScroll',
                    vHasPermi: ['applyMe:all']
                }
            ],
            comps: [
                'CoworkingOAApprovalScrollWrapper',
                'CoworkingCampusOAOAApprovalMyScroll'
            ],
            idx: 0
        }
    },
    created() {
        if (this.$route.query.activeName) {
            this.activeName = this.$route.query.activeName || '0'
        }
    },
    created () {
        this.init();
        try {
            const hasHis = JSON.parse(window.localStorage.getItem('J_CoworkingOAApproval'))
            if(hasHis && Object.keys(hasHis).length > 0) {
                this.handlerChangeTabs(hasHis)
            }
        } catch(e){};
    },
    activated () {
        this.init();
        console.log('[OA审批-activated]')
    },
    methods: {
        init () {
            if (this.$route.query.activeName) {
                this.handlerChangeTabs({
                    id: this.$route.query.activeName
                })
            }else {
                this.tabList = tabPageHasAuth(this.tabList);

            }
            this.comps = this.tabList.map(item => item.com);
        },
        /**
         * *切换tabs相关
         * handlerChangeTabs
         * */
        handlerChangeTabs (data) {
            console.log(data,'data--data')
            localStorage.setItem('J_CoworkingOAApproval', JSON.stringify(data))
            this.activeName = data.id;
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.idx = index;
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
        setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0;
        },
        /**
         * @Description: 各列表点击更多
         * @Author: 武东超
         * @Date: 2024-09-04 17:53:56
         */   
        handlerMore(data) {
            this.idx = 1;
            let obj = this.tabList.filter(item => {
                return data.id == item.id;
            })[0];
            this.handlerChangeTabs(obj);
        },     
    },
    computed: {
        //['waitMe:export','doneMe:export','ccMe:export','applyMe:export']
        //导出
        upLoadAuth () {
            console.log('111222');
            console.log(this.activeName,'22222');
            switch (this.activeName) {
                case '1':
                    return ['waitMe:export']
                case '2':
                    return ['doneMe:export']
                case '3':
                    return ['ccMe:export']
                case '4':
                    return ['applyMe:export']
                default :
                break;
            }
        },
        //详情 ['waitMe:info','doneMe:info','ccMe:info','applyMe:info']
        detailValue(){
            switch (this.activeName) {
                case '1':
                    return ['waitMe:info']
                case '2':
                    return ['doneMe:info']
                case '3':
                    return ['ccMe:info']
                case '4':
                    return ['applyMe:info']
                default :
                break;
            }
        },
    }
}
</script>

<style scoped lang="scss">
    .tabs-list {
        border-radius: 4px 0 0 4px;
        display: flex;
        width: 100%;
        height: 48px;
        line-height: 48px;
        background-color: #fff;
        padding-left: 30px;

        .tabs-item {
          min-width: 56px;
          position: relative;
          text-align: center;
          font-size: 14px;
          color: #333333;
          cursor: pointer;
          margin-right: 48px;
        }

        .tabs-item.current {
            position: relative;
            color: #3c7fff ;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: #3C7FFF;
                border-radius: 1px;
            }
        }
      }
</style>
