
<template>
    <div></div>
</template>

<script>
import { mapState } from "vuex";
import * as dd from "dingtalk-jsapi";
import { getCode } from "@/libs/dingtalk";
import { setToken } from "@/libs/auth";
import { getToken } from "../libs/auth";
import store from '@/store'

export default {
    name: "DingLogin",
    created() {
        this.init1()
    },
    computed: {
        ...mapState({
            projectMark: state => state.projectMark,
        })
    },
    methods: {
        async init1() {
            let urlParam = this.$route.query
            if (urlParam.name) {
                // 查看当前是否可以获取到token
                if (getToken()) {
                    // 未过期 直接跳转
                    // this.$router.push({
                    //     // name: urlParam.name,
                    //     path:'/sManager/oa/meeting/Attendance',
                    //     query: {
                    //         name:urlParam.name,
                    //         startDate: urlParam.startDate,
                    //         endDate: urlParam.endDate,
                    //     }
                    // })
                    console.log(getToken(),'getToken()')
                    let url=this.$route.query.url
                    let startDate = this.$route.query.startDate;
                    let endDate = this.$route.query.endDate;
                    location.replace(url+"?startDate=" + startDate + "&endDate=" + endDate+ "&token=" +getToken())
                    // window.open(url+"?startDate=" + startDate + "&endDate=" + endDate+ "&token=" +getToken(), "myWindow")
                    // this.$router.push({
                    //     // name: urlParam.name,
                    //     path:'/sManager/oa/meeting/Attendance',
                    // })
                } else {
                    // 获取不到token
                    console.log(urlParam.name, 'urlParam.name')
                    this.$router.push({
                        path: '/login',
                        // query: {
                        //     redirect: urlParam.name,
                        //     startDate: urlParam.startDate,
                        //     endDate: urlParam.endDate,
                        // }
                        query:urlParam
                    })
                }
            }
        }

    },
    init() {
        let urlParam = this.$route.query
        if (dd.env.platform !== 'notInDingTalk' && urlParam.name) {
            const projectMark = this.projectMark || ''
            getCode(code => {
                this._fet('/auth/sc/dingLoginHszx', {
                    code: code,
                    projectMark: projectMark,
                    mode: 'pc'
                }).then((res) => {
                    if (res.data.code === '200') {
                        setToken(res.data.data.token);
                        this.$router.push({
                            name: urlParam.name,
                            query: urlParam
                        })
                    }
                })
            }, projectMark)
        } else {
            this.$router.push({
                name: '/login'
            });
        }
    }
}
</script>

<style scoped></style>
