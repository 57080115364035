<template>
  <!-- 协同办公 > 校园OA -->
  <KeepAliveRouterView />
</template>

<script>
export default {
  name: "CoworkingCampusOA"
}
</script>

<style scoped>

</style>
