<template>
    <!-- 申请详情 -->
    <coworking-campus-o-a-o-a-approval-form-detail-scroll-wrap/>
</template>

<script>
import CoworkingCampusOAOAApprovalFormDetailScrollWrap from '@/components/scrollWrapper/CoworkingCampusOAOAApprovalApplyDetail.vue'
export default {
    name: "CoworkingCampusOAOAApprovalApplyDetail",
    components: {
        CoworkingCampusOAOAApprovalFormDetailScrollWrap
    }
}
</script>

<style scoped>

</style>
