<template>
  <!-- 校园OA > 分组管理 -->
  <CoworkingCampusOAGroupManageScrollWrapper />
</template>

<script>
import CoworkingCampusOAGroupManageScrollWrapper from '@/components/scrollWrapper/CoworkingCampusOAGroupManage'

export default {
  name: "CoworkingCampusOAGroupManage",
  components: {
    CoworkingCampusOAGroupManageScrollWrapper
  }
}
</script>
